<template>
  <v-container fluid>
<!--    <v-card>
      <v-card-title>
        Inspection
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search "
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="test"
        :headers="headers"
        :items="ODP_data"
        :search="search"
      >
        <template v-slot:item.action="{ item }">
          <v-btn
            dark
            x-small
            text
            color="black"
            @click="getPDF(item)"
          >
            <v-icon>description</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>-->
    <data_table
      :displayname="'Inspection'"
      :options="false"
      :items="ODP_data"
      :headers="headers"
      :loader="get_data_load"
      :search_text="search_text"
      :getPDF_func="getPDF"
      :pdf_params="pdfparams"
      :type="'BHA'"
      :search="search"
      :item-class="'orange'"
      :defaultSort="'Inspection_Date'"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import data_table from '@/components/data_table.vue'
export default {
  name: 'insp',
  components: { data_table },
  data () {
    return {
      pdfparams: 'Placeholder',
      search_text: 'Search (Order No, Ref etc..)',
      search: '',
      headers: [
        {
          text: 'Order No',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        { text: 'Serial No', value: 'Serial_No' },
        { text: 'Description', value: 'Description' },
        { text: 'Connection 1', value: 'Connection_1' },
        { text: 'Connection 2', value: 'Connection_2' },
        { text: 'PO', value: 'PO' },
        { text: 'Ref', value: 'Ref' },
        { text: 'Asset', value: 'Asset' },
        { text: 'Inspection Date', value: 'Inspection_Date' },
        { text: 'Spec', value: 'Spec' },
        { text: 'PDF', value: 'action', sortable: false },
        { text: 'Download Selected', value: 'multiple', sortable: false }

      ],
      ODP_data: [
      ],
      reporttype: ''
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'get_data_load', 'getAsset', 'getFleet'])
  },
  mounted () {
    this.set_data_load(true)
    this.query = this.$route.query.q
    if (this.query !== undefined) {
      this.search = this.query
    }
    var response_NEW = {}
    var response_OLD = {}
    axios
      .get('/inspectionBHA', {
        params: { username: this.$store.getters.getUsername }
      })
      .then((response) => {
        response_NEW = response.data
        axios.get('/inspectionBHA_OLD', {
        params: { username: this.$store.getters.getUsername }
      })
      .then((response) => {
        response_OLD = response.data

        var reportList = []
        reportList.push(...response_NEW["multi_detail"], ...response_NEW["dim"], ...response_NEW["misc"], ...response_NEW["elevator"], ...response_OLD["multi_detail"], ...response_OLD["dim"], ...response_NEW["misc"], ...response_NEW["elevator"])

        reportList = { BHA: reportList }
        this.ODP_data = this.$filterAsset(reportList, this.getFleet, this.getAsset, 'Asset', 'BHA')
        this.ODP_data = this.$formatData(this.ODP_data, ['Inspection_Date'])
        this.set_data_load(false)
      })
      })
  },
  methods: {
    getPDF (type, item, pdf_params) {
      this.reporttype = item.report_type
      if (this.reporttype === 'Multi') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Multi_Detail_Report', 'ODP_BHA_Multi_Detail_Report_Print', 'Multi_Detail', '', '', item.Serial_No, '']
        )
      } else if (this.reporttype === 'Elevator') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Elevator_Links_V2', 'ODP_BHA_Elevator_Links_Print_V2', 'Elevator_Links', '', '', item.Serial_No, item.Description]
        )
      } else if (this.reporttype === 'Dimentional') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Dimentional_Report', 'ODP_BHA_Dimentional_Report_Print', 'Dimentional', '', '', item.Serial_No, item.Description]
        )
      } else if (this.reporttype === 'Miscellaneouse') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Miscellaneouse_Report', 'ODP_BHA_Miscellaneouse_Print', 'Miscellaneous', '', '', item.Serial_No, item.Description]
        )
      } else if (this.reporttype === 'Dimentional_OLD') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Report', 'ODP_BHA_Print', 'Dimentional', 'Order_Nr;Serial_Number', '', item.Serial_No, item.Description]
        )
      } else if (this.reporttype === 'Miscellaneouse_OLD') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_Misc_Report', 'ODP_Misc_Report_Print', 'Miscellaneous', 'Order_No;Serial_No', '', item.Serial_No, item.Description]
        )
      } else if (this.reporttype === 'Elevator_OLD') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Elevator_Links', 'ODP_BHA_Elevator_Links_Print', 'Elevator_Links', 'Ordre_No;Serial_Nr', '', item.Serial_No, item.Description]
        )
      }
    },
    set_age_color() {

    }
  }
}
</script>

<style>
</style>
