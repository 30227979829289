<template>
  <v-card dark color="transparent" width="95%" class="mx-auto mt-10" elevation="0">
    <v-card-title class="text-h5">{{ displayname }}</v-card-title>
    <v-container fluid class="d-flex justify-center"
    >
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        :label="search_text"
        hide-details
      ></v-text-field>
      <v-spacer/>
      <v-checkbox
        v-if="options"
        class="d-inline mx-4"
        v-model="selected"
        label="Completed"
        value="completed"
      ></v-checkbox>
      <v-checkbox
        v-if="options"
        class="d-inline mx-4"
        v-model="selected"
        label="Ongoing"
        value="ongoing"
      ></v-checkbox>
      <v-select
        v-model="selected_columns"
        placeholder="Filter columns"
        :items="headers"
        multiple
        class="temp"
        v-if="selected_columns.length > 0"
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">Selected columns: {{ selected_columns.length }}</span>
        </template>
      </v-select>
      <v-btn color="transparent" elevation="0" @click="selectAllPDF" v-if="type === 'BHA'">{{ pdf_button_text }}</v-btn>
    </v-container>
    <v-data-table @update:page="pageChange" @current-items="getShowingItems" :loading="loader" :headers="headers" :items="table_items" :search="search" :item-class="'orange'" class="elevation-1 custom-table" :sort-by="defaultSort" :sort-desc="true" :options.sync="pagination" :footer-props="{'items-per-page-options':[5, 10, 50, 100, -1]}">
      <template v-slot:header.multiple="{ header }">
        <div @click="multiple_download" style="cursor: pointer; border: 1px solid #1C355E; text-align: center;">
          <span>Download Selected</span>
          <v-icon color="#1C355E" >mdi-download</v-icon>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn x-small text @click="getPDF_func(type, item, pdf_params)">
          <v-icon>description</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.multiple="{ item }">
        <v-checkbox v-model="selectedPDF" :value="item" multiple></v-checkbox>
      </template>
      <template v-slot:item.Inspection_Date ="{ item }">
        <td :style="{backgroundColor: date_colorizer(item.Date_Formatted)}">
          <span>{{ item.Date_Formatted }}</span>
        </td>
      </template>
      <template v-slot:item.Return_Date ="{ item }">
        <span>{{ item.Date_Formatted }}</span>
      </template>
      <template v-slot:item.Returned_Date ="{ item }">
        <span>{{ item.Date_Formatted }}</span>
      </template>
      <template v-slot:item.Date ="{ item }">
        <span>{{ item.Date_Formatted }}</span>
      </template>
      <template v-slot:item.Connection_1 ="{ item }" >
        <span>{{ item.Connection_1 }}</span>
      </template>
      <template v-slot:item.Connection_2 ="{ item }" >
        <span>{{ item.Connection_2 }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
var moment = require('moment')
moment().format()
export default {
  name: 'data_table',
  props: [
    'displayname',
    'search_text',
    'options',
    'getPDF_func',
    'loader',
    'headers',
    'items',
    'pdf_params',
    'type',
    'defaultSort'
  ],
  data () {
    return {
      selected: ['completed', 'ongoing'],
      ODP_data: this.items,
      selected_columns: [],
      search: '',
      currentRows: [],
      selectedPDF: [],
      zip: new JSZip(),
      pagination: {
                ascending: false,
                sortBy: [this.defaultSort],
                itemsPerPage: 100,
                page: 1
            }

    }
  },
  methods: {
    pageChange() {
      this.selectedPDF = []
    },
    getShowingItems(items) {
      this.currentRows = items
    },
    selectAllPDF() {
      if (this.selectedPDF.length > 0) {
        this.selectedPDF = []
      } else {
        this.selectedPDF = this.currentRows
      }
    },
    downloadZip() {
      axios({
        url: '/getPDFmultiple',  // Your Flask endpoint
        method: 'GET',
        responseType: 'blob',  // Important: This tells Axios to treat the response as a blob
        params: {
          selected: JSON.stringify(this.selectedPDF)  // Pass your selected PDFs
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pdf_files.zip'); // File name for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading the ZIP:', error);
      });
    },

    multiple_download() {
      if (this.selectedPDF.length < 1) {
        alert("Please select PDFs to download first.")
        return
      }

      const rows = [];
      for (var i = 0; i < this.selectedPDF.length; i++) {
        var item = this.selectedPDF[i]
        var reporttype = item.report_type
        if (reporttype === 'Multi') {
          
          var dict = {
            'id_name': 'Order_No',
            'type': true,
            'container_location': 'ODP_BHA_Multi_Detail_Report',
            'id_no': item.Order_No,
            'pdf_location': 'ODP_BHA_Multi_Detail_Report_Print',
            'description': 'Multi_Detail',
            'pdf_location2': '',
            'pdf_location3': '',
            'serial_no': item.Serial_No,
            'description_name': '',
            'report_id': item.Report_ID 
          }
          rows.push(dict)

        } else if (reporttype === 'Elevator') {

          var dict = {
            'id_name': 'Order_No',
            'type': true,
            'container_location': 'ODP_BHA_Elevator_Links_V2',
            'id_no': item.Order_No,
            'pdf_location': 'ODP_BHA_Elevator_Links_Print_V2',
            'description': 'Elevator_Links',
            'pdf_location2': '',
            'pdf_location3': '',
            'serial_no': item.Serial_No,
            'description_name': item.Description,
            'report_id': item.Report_ID 
          }
          rows.push(dict)

        } else if (reporttype === 'Dimentional') {

          var dict = {
            'id_name': 'Order_No',
            'type': true,
            'container_location': 'ODP_BHA_Dimentional_Report',
            'id_no': item.Order_No,
            'pdf_location': 'ODP_BHA_Dimentional_Report_Print',
            'description': 'Dimentional',
            'pdf_location2': '',
            'pdf_location3': '',
            'serial_no': item.Serial_No,
            'description_name': item.Description,
            'report_id': item.Report_ID 
          }
          rows.push(dict)
          
        } else if (reporttype === 'Miscellaneouse') {

          var dict = {
            'id_name': 'Order_No',
            'type': true,
            'container_location': 'ODP_BHA_Miscellaneouse_Report',
            'id_no': item.Order_No,
            'pdf_location': 'ODP_BHA_Miscellaneouse_Print',
            'description': 'Miscellaneous',
            'pdf_location2': '',
            'pdf_location3': '',
            'serial_no': item.Serial_No,
            'description_name': item.Description,
            'report_id': item.Report_ID 
          }
          rows.push(dict)

        } else if (reporttype === 'Dimentional_OLD') {

          var dict = {
            'id_name': 'Order_No',
            'type': true,
            'container_location': 'ODP_BHA_Report',
            'id_no': item.Order_No,
            'pdf_location': 'ODP_BHA_Print',
            'description': 'Dimentional',
            'pdf_location2': 'Order_Nr;Serial_Number',
            'pdf_location3': '',
            'serial_no': item.Serial_No,
            'description_name': item.Description,
            'report_id': item.Report_ID 
          }
          rows.push(dict)
          
        } else if (reporttype === 'Miscellaneouse_OLD') {

          var dict = {
            'id_name': 'Order_No',
            'type': true,
            'container_location': 'ODP_Misc_Report',
            'id_no': item.Order_No,
            'pdf_location': 'ODP_Misc_Report_Print',
            'description': 'Miscellaneous',
            'pdf_location2': 'Order_No;Serial_No',
            'pdf_location3': '',
            'serial_no': item.Serial_No,
            'description_name': item.Description,
            'report_id': item.Report_ID 
          }
          rows.push(dict)
          
        } else if ( reporttype === 'Elevator_OLD') {

          var dict = {
            'id_name': 'Order_No',
            'type': true,
            'container_location': 'ODP_BHA_Elevator_Links',
            'id_no': item.Order_No,
            'pdf_location': 'ODP_BHA_Elevator_Links_Print',
            'description': 'Elevator_Links',
            'pdf_location2': 'Ordre_No;Serial_Nr',
            'pdf_location3': '',
            'serial_no': item.Serial_No,
            'description_name': item.Description,
            'report_id': item.Report_ID 
          }
          rows.push(dict)
          
        }
      }
      this.loader = true
      axios({
        url: '/getPDFmultipleBHA',  // Your Flask endpoint
        method: 'GET',
        responseType: 'blob',  // Important: This tells Axios to treat the response as a blob
        params: {
          selected: JSON.stringify(rows)  // Pass your selected PDFs
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'OceanDataPool_BHA.zip'); // File name for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.selectedPDF = []
        this.loader = false
      })
      .catch((error) => {
        console.error('Error downloading the ZIP:', error);
        this.loader = false
      });

    },
    date_colorizer(insp_date){
      if (this.getUsername === "Innovar Solution AS"){
        var insp = moment(insp_date,"DD.MM.YYYY")
        var now = moment(moment().format("DD.MM.YYYY"),"DD.MM.YYYY")
        var n_months = now.diff(insp,'months')
        if (n_months < 5){
          return "green"
        }else if (n_months >= 5 && n_months <= 6){
          return "orange"
        }
        return "red"
      }
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getAsset', 'getFleet', 'getPipeType', 'getExcludedAssets']),
    pdf_button_text() {
      if (this.selectedPDF.length > 0) {
        return 'Unselect all pdf'
      } else {
        return 'Select all pdf'
      }
    },
    table_items () {
      if (this.options) {
        return this.filteredData
      } else {
        return this.items.filter(order => {
          return !this.getExcludedAssets.includes(order.Asset)
        })
      }
    },
    filteredData () { // Filters data using 'completed' and 'ongoing'.'
      if (this.ODP_data[this.getPipeType]) {
        if (this.getFleet === true) {
          if (this.selected.length === 2) {
            
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return !this.getExcludedAssets.includes(order.Asset)
            })

          } else if (this.selected.includes('completed')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'Yes' && !this.getExcludedAssets.includes(order.Asset)
            })
          } else if (this.selected.includes('ongoing')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed !== 'Yes' && !this.getExcludedAssets.includes(order.Asset)
            })
          }
        } else {
          if (this.selected.length === 2) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Asset === this.getAsset
            })
            // eslint-disable-next-line eqeqeq
          } else if (this.selected.includes('completed')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'Yes' && order.Asset === this.getAsset
            })
          } else if (this.selected.includes('ongoing')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'No' && order.Asset === this.getAsset
            })
          }
        }
      }
    },

  }
}
</script>

<style scoped>

::v-deep .v-input__slot {
  justify-content: center;
}

::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

.v-input.v-text-field.v-select {
  max-width: 300px;
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}

.v-menu__content {
  text-align: start !important;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th{
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child{
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child{
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

</style>
